<template>
  <cs-segment class="cs-page-default">
    <cs-container>
      <cs-sections
        :data="$store.state.page.content.sections"
        imgRadius="8px" />
    </cs-container>
  </cs-segment>
</template>

<script>
export default {
  name: 'cs-page-default',
}
</script>

<style lang="sass">
.cs-page-default
  margin: m(16) 0 m(4) 0

@media print
  .cs-page-default
    margin: 0
</style>
